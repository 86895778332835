<template>
  <div id="app">
    <mapView/>
  </div>
</template>

<script>
import mapView from '@/components/map/map.vue'
export default {
  name: 'Home',
  components: {
    mapView,
  },
}
</script>
