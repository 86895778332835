<template>
  <div>
    <el-row :gutter="10" class="eNumberListArea">
      <el-col :span="span" v-for="item in rowData" :key="'item_'+item.name">
        <div class="eNumberListContain">
          <div class="body">
            <div >
              <template v-for="prop in item.props">
                <div class="eNumberList" :key="'prop_'+prop.label" v-if="type==prop.label">{{prop.value}} <span style="font-size:32px">{{prop.label}}</span> </div>
              </template>
            </div>
          </div>
          <div class="title">{{item.name}}</div>
        </div>
      </el-col>
    </el-row>
   
  </div>
</template>

<script>
export default {
  data(){
    return{
      type:null,
    }
  },
  props:{
    title:{
      type:String,
      default(){return''}
    },
    height:{
      type:String,
      default(){return''}
    },
    span:{
      type:Number,
      default(){return 6}
    },
    rowData:{
      type:Array,
      default(){return []}
    },
  },
  methods:{
    init(){
    setInterval(()=>{
        this.switchType()
      },1000*5)
    },
    switchType(){
      let typeArr = this.rowData[0].props.map(e=>e.label);
      let type = this.type;
      let currentIndex = typeArr.indexOf(type);
      let nextIndex = currentIndex == 2 ? 0 : currentIndex+1;
      this.type = typeArr[nextIndex]
    },
  },
  mounted(){
    this.init()
  }
}
</script>