<template>
<div class="cardContain">
    <div class="title">{{title}}</div>
    <div class="body corner" style="display:flex;padding:10px 0">
      <div class="seamlessUlScoll" :style="{width: '100%', height: height}">
        <vue-seamless-scroll :data="rowData.row" :class-option="defaultOption" >
            <ul>
                <li v-for="(item,index) in rowData.row" :key="index" >
                  <el-row :gutter="10">
                    
                    <el-col :span="24/rowData.column.length" v-for="_column in rowData.column" :key="index+'_'+_column.key">
                      {{item[_column.key]}}
                    </el-col>
                    <!-- <el-col :span="_columnIndex==0?6:18" v-for="(_column,_columnIndex) in rowData.column" :key="index+'_'+_column.key">
                      {{item[_column.key]}}
                    </el-col> -->
                  </el-row>
                </li>
            </ul>
        </vue-seamless-scroll>
      </div>
    </div>
  </div>
</template>
 
<script>
    import vueSeamlessScroll from 'vue-seamless-scroll'
    export default {
        components: {
            vueSeamlessScroll
        },
        props:{
          title:{
            type:String,
            default(){return''}
          },
          height:{
            type:String,
            default(){return''}
          },
          rowData:{
            type:Object,
            default(){return {}}
          },
        },
        // 监听属性 类似于data概念
        computed: {
            defaultOption () {
                return {
                    step: 2, // 数值越大速度滚动越快
                    limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
                    hoverStop: false, // 是否开启鼠标悬停stop
                    direction: 1, // 0向下 1向上 2向左 3向右
                    openWatch: true, // 开启数据实时监控刷新dom
                    singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
                    singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
                    waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
                }
            }
 
        },
    }
</script>

