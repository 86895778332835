<template>
<div id="test">
  <div id="DataSet" :DPR="DPR">
    <transition name="el-fade-in-linear">
      <video-bg v-if="!removeElements" v-show="videoCover" id="videoCover" :sources="[require('@/assets/bg.mp4')]" :img="videoCoverImg"/>
    </transition>
    <transition name="el-fade-in-linear">
      <div v-if="!removeElements" v-show="videoCover" class="videoCoverPic" :style="{'background-image':videoCoverImgURl}"></div>
    </transition>
    <div>
      <textHeader/>
      <!-- 左 -->
      <div class="cardView" style="left:10px">
        <eNumber v-if="residentPopulation" title="全县户籍人口" height='3vh' :rowData="residentPopulation"/>
        <eCharts 
          height='10vh' 
          :legend="false"
          v-if="households"
          :rowTitle='households.data.householdNumber[0].name' 
          :rowType="households.type" 
          :rowData="households.data.householdNumber" 
          :changeTitle='households.data.personNumber[0].name' 
          :changeType="households.type" 
          :changeData="households.data.personNumber"  
          :DPR="DPR"/>
        <eCharts 
          height='10vh' 
          :legend="false"
          v-if="households"
          :rowTitle='households.data.averageHouseholdNumber[0].name' 
          :rowType="households.type" 
          :rowData="households.data.averageHouseholdNumber" 
          :changeTitle='households.data.householdNumber[0].name' 
          :changeType="households.type" 
          :changeData="households.data.householdNumber" 
          :DPR="DPR"/>
        <eCharts 
          height='55vh' 
          v-if="populationComposition"
          :rowTitle='populationComposition.title' 
          :rowType="populationComposition.type" 
          :rowData="populationComposition.data"
          :DPR="DPR"/>
      </div>
      <!-- 中 -->
      <div class="cardView" style="left:calc(20vw + 20px);width:calc(60vw - 40px);top: 260px">
        <el-row :gutter="10" class="topMenu">
          <el-col :span="6" v-for="item in topItemList" :key="'topItem_'+item">
            <div :class="['topItem',currentTopItem==item?'active':'']" @click="ClickTopItem(item)">{{item}}</div>
          </el-col>
        </el-row>
        <div v-if="currentTopItem=='人民武装动员'">
          <el-row :gutter="10">
            <el-col :span="12">
              <!-- <eCharts 
              height='73.5vh' 
              v-if="populationComposition"
              :rowTitle='populationComposition.title' 
              rowType="pie" 
              direction="y" 
              :rowData="populationComposition.data"
              :DPR="DPR"/> -->
              <eCharts 
              height='73.5vh' 
              :legend="false"
              v-if="personJobSkill"
              :rowTitle='personJobSkill.title+"矩阵"' 
              :rowType="personJobSkill.type" 
              :rowData="personJobSkill.DataTree"  
              :changeTitle='personJobSkill.title+"TOP20"' 
              :changeData="personJobSkill.DataBar" 
              changeType="bar" 
              :DPR="DPR"/>
            </el-col>
            <el-col :span="12">
              <eCharts height='28vh' v-if="countType5" :legend='false' :rowTitle='countType5.title' :rowType="countType5.type" :rowData="countType5.data" :DPR="DPR"/>
              <eCharts 
              height='11vh' 
              :legend="false"
              v-if="households"
              :rowTitle='households.data.averageHouseholdNumber[0].name' 
              rowType="bar" 
              :rowData="households.data.averageHouseholdNumber"
              :DPR="DPR"/>
              <eCharts 
              height='11vh' 
              :legend="false"
              v-if="households"
              :rowTitle='households.data.householdNumber[0].name' 
              rowType="bar" 
              :rowData="households.data.householdNumber"
              :DPR="DPR"/>
              <eCharts 
              height='11vh' 
              :legend="false"
              v-if="households"
              :rowTitle='households.data.personNumber[0].name' 
              rowType="bar" 
              :rowData="households.data.personNumber"
              :DPR="DPR"/>
            </el-col>
          </el-row>
          
          
          
        </div>
        <div v-if="currentTopItem=='人防动员'">
          <eTable :title='"港口 "+ portPortBerth.row.length' height='20vh' v-if="portPortBerth" :rowData="portPortBerth"/>
          <eTable :title='"学校 "+ schoolList.row.length' height='47vh' v-if="schoolList" :rowData="schoolList"/>
        </div>
        <div v-if="currentTopItem=='经济动员'">
          <el-row :gutter="10">
            <el-col :span="12">
              <eCharts height='73.5vh' v-if="countType11" :legend='false' :rowTitle='countType11.title' :rowType="countType11.type" :rowData="countType11.data" :DPR="DPR"/>
            </el-col>
            <el-col :span="12">
              <eCharts height='34.6vh' v-if="hospital" :legend='false' :rowTitle='hospital.title' :rowType="hospital.type" :rowData="hospital.data" :DPR="DPR"/>
              <eCharts height='34.6vh' v-if="countType10" :legend='false' :rowTitle='countType10.title' :rowType="countType10.type" :rowData="countType10.data" :DPR="DPR"/>
            </el-col>
          </el-row>
        </div>
        <div v-if="currentTopItem=='装备动员'">
          <el-row :gutter="0">
            <el-col :span="24">
              <eCharts height='24vh' v-if="specialEquipment && preEquipment && emergencyEquipment" :legend='false' rowTitle='装备统计' rowType="bar" 
              :rowData="[{name:'装备统计',props:[{label:'特种设备使用登记证',value:specialEquipment.row.length},{label:'预征预储装备潜力',value:preEquipment.row.length},{label:'应急专用装备',value:emergencyEquipment.row.length}]}]" :DPR="DPR"/>
            </el-col>
            <el-col :span="8">
              <eTable :title='"特种设备使用登记证 "+ specialEquipment.row.length' height='44.4vh' v-if="specialEquipment" :rowData="specialEquipment"/>
            </el-col>
            <el-col :span="8">
              <eTable :title='"预征预储装备潜力 "+ preEquipment.row.length' height='44.4vh' v-if="preEquipment" :rowData="preEquipment"/>
            </el-col>
            <el-col :span="8">
              <eTable :title='"应急专用装备 "+ emergencyEquipment.row.length' height='44.4vh' v-if="emergencyEquipment" :rowData="emergencyEquipment"/>
            </el-col>
          </el-row>
        </div>
        <div v-if="currentTopItem=='科技动员'">
          <el-row :gutter="0">
            <el-col :span="12">
              <eTable :title='"科技型中小企业 "+ comWareroom.row.length' height='72vh' v-if="comWareroom" :rowData="comWareroom"/>
            </el-col>
            <el-col :span="12">
              <eCharts height='34.6vh' v-if="countType10" :legend='false' :rowTitle='countType10.title' rowType="pie" :rowData="countType10.data" :DPR="DPR"/>
              <eTable :title='"高新技术企业 "+ highTechCom.row.length' height='33vh' v-if="highTechCom" :rowData="highTechCom"/>
            </el-col>
          </el-row>
        </div>
        <div v-if="currentTopItem=='信息动员'">
          <el-row :gutter="0">
            <eCharts height='24vh' v-if="infomationMobilizationSum" :legend='false' :rowTitle='infomationMobilizationSum[0].name' rowType="bar" :rowData="infomationMobilizationSum" :DPR="DPR"/>
            <el-col :span="12">
              <eTable :title='"计算机操作员 "+ 计算机操作员.number' height='20vh' v-if="计算机操作员" :rowData="计算机操作员"/>
            </el-col>
            <el-col :span="12">
              <eTable :title='"电子计算机维修工 "+ 电子计算机维修工.number' height='20vh' v-if="电子计算机维修工" :rowData="电子计算机维修工"/>
            </el-col>
            <el-col :span="12">
              <eTable :title='"计算机网络管理员 "+ 计算机网络管理员.number' height='20vh' v-if="计算机网络管理员" :rowData="计算机网络管理员"/>
            </el-col>
            <el-col :span="12">
              <eTable :title='"软件设计工程师 "+ 软件设计工程师.number' height='20vh' v-if="软件设计工程师" :rowData="软件设计工程师"/>
            </el-col>
          </el-row>
        </div>
        <div v-if="currentTopItem=='交战动员'">
          <el-row :gutter="0">
            <el-col :span="12">
              <eCharts 
              v-if="countType8"
              :legend="false"
              height='74.4vh' 
              direction="y" 
              :rowTitle='countType8.title' 
              :rowType="countType8.type"  
              :rowData="countType8.data"  
              :DPR="DPR"/>
            </el-col>
            <el-col :span="12">
              <eCharts 
              v-if="countType6"
              :legend="false"
              height='74.4vh' 
              direction="y" 
              :rowTitle='countType6.title' 
              :rowType="countType6.type"  
              :rowData="countType6.data"  
              :DPR="DPR"/>
            </el-col>
          </el-row>
        </div>
        <div v-if="currentTopItem=='政治动员'">
          <div style="height:20vhheight: 77vh;overflow-y: auto;">
            <el-row :gutter="0">
              <el-col :span="12" v-for="(item,index) in article" :key="'article_'+index">
                <eArticle :article='item'/>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
      <!-- 右 -->
      <div class="cardView" style="right:10px">
        <eNumberList height='1vh' :rowData="dataF" :span="12"/>
        <eSwiper title='' height='51vh' :rowData="dataG"/>
      </div>
    </div>
  </div>
  <div id="backgroundMapCover"></div>
  <div id="backgroundMap"></div>
</div>
</template>

<script>
import VideoBg from 'vue-videobg'
import eCharts from '@/components/dataSet/eCharts.vue'
import eNumber from '@/components/dataSet/eNumber.vue'
import eTable from '@/components/dataSet/eTable.vue'
import eNumberList from '@/components/dataSet/eNumberList.vue'
import eSwiper from '@/components/dataSet/eSwiper.vue'
import eArticle from '@/components/dataSet/eArticle.vue'
import textHeader from '@/components/dataSet/textHeader.vue'

export default {
  components: {
    eCharts,
    eNumber,
    eTable,
    textHeader,
    eNumberList,
    eSwiper,
    VideoBg,
    eArticle
  },
  data(){
    return {
      removeElements:false,
      map:null,
      rotation:17,
      pitch:50,
      zoom: 19,
      center: new this.$Map.LatLng(34.736104,116.930169),
      videoCover:true,
      videoCoverImg:require('@/assets/bg.jpg'),
      videoCoverImgURl:`url(${require('@/assets/bg.jpg')})`,
      DPR:4,
      residentPopulation:0,
      dataF:[],
      dataG:[],
      populationComposition:null,
      households:null,
      personJobSkill:null,
      hospital:null,
      countType10:null,
      countType11:null,
      countType5:null,
      countType8:null,
      countType6:null,
      specialEquipment:null,
      emergencyEquipment:null,
      preEquipment:null,
      schoolList:null,
      portPortBerth:null,
      topItemList:["人民武装动员","经济动员","人防动员","交战动员","政治动员","装备动员","科技动员","信息动员"],
      currentTopItem:null,
      comWareroom:null,
      infomationMobilizationSum:null,
      计算机操作员:null,
      电子计算机维修工:null,
      计算机网络管理员:null,
      软件设计工程师:null,
      article:[]
    }
  },
  mounted(){
    this.Init()
  },
  methods:{
    Init(){
      this.PopulationComposition();
      this.Households();
      this.PersonJobSkillList();
      this.Hospital();
      this.CountType10();
      this.CountType11();
      this.CountType5();
      this.CountType8();
      this.CountType6();
      this.SpecialEquipment();
      this.EmergencyEquipment();
      this.PreEquipment();
      this.SchoolList();
      this.PortPortBerth();
      this.ResidentPopulation();
      this.ComWareroom();
      this.HighTechCom();
      this.InfomationMobilization();
      this.InitMap();
      setTimeout(()=>{
        this.videoCover = false
      },10000);
      setTimeout(()=>{
        this.removeElements = true
      },11000);
      this.InitReport();
      this.GetArticle();
      setInterval(()=>{
        this.InitReport()
      },1000*60*2)
      document.querySelectorAll('a[href="http://map.qq.com?ref=jsapi_v3"]')[0].parentNode.parentNode.remove()
    },
    GetArticle(){
       let self = this;
        self.$axios.get('/api/article/list?pageNum=1&pageSize=50').then(res=>{
          let result = res.records.filter(e=>e.status==1);
          self.article = result
      });
    },
    InitReport(){
      let self = this;
      self.dataF = [];
      self.dataG = [];
      self.$axios.get('/api/info-report-option/list').then(res=>{
        res.records.forEach(e=>{
          self.$axios.get(`/api/info-report/count/${e.guid}`).then(result=>{
            self.dataF.push({
              name:result['上报分类名称'],
              props:[ 
                {label:'已通过',value:result['审核通过上报分类总数']}, 
                {label:'未通过',value:result['审核未通过上报分类总数']}, 
                {label:'已审核',value:result['已审核上报分类总数']}, 
                {label:'待审核',value:result['未审核上报分类总数']}, 
              ]
            })
          })
        })
      });
      self.$axios.get(`/api/info-report/list`).then(res=>{
        let result = res.records.map(e=>{
          let status = '';
          if(e.isCheck==-1){status='审核不通过'}
          if(e.isCheck==2){status='审核通过'}
          if(e.isCheck==0){status='待审核'}
          return {
            mName:e.mName,
            status:status,
            des:e.content,
            type:e.type,
            sendTime:e.sendTime
          }
        })
        self.dataG = result
      })
    },
    InitMap(){
      let self = this;
      let drawContainer = document.getElementById('backgroundMap');
      self.map = new self.$Map.Map(drawContainer, {
          zoom: self.zoom,
          scrollable:false,
          pitch: self.pitch,
          center: self.center,
          draggable: false,
          doubleClickZoom:false,
          viewMode:'3D',
          rotation:self.rotation,
          mapStyleId: 'style2',
          renderOptions: {
              enableBloom: false, //泛光
              fogOptions: {
                  color:'rgb(3,11,28)'
              }
          },
          baseMap: [
              { type: "vector"}, //设置矢量底图
              {
                  type: "traffic",
                  features: ["base", "flow"], //路况图要素类型
                  opacity: 1, //路况线网图的透明度
              },
          ],
      }).removeControl("floor").removeControl("rotation").removeControl("scale").removeControl("zoom");
      new self.$Map.visualization.Radiation({
          styles: {
            style1: {
              fillColor: "rgba(55,129,234,0.4)", //设置辐射圈填充颜色
              strokeColor: "rgba(55,129,234,0.9)",
              strokeWidth: 100,
            },
          },
          processAnimation: {
            duration: 3000, //辐射圈半径从0动态过渡到最大半径的时长，单位为ms
          },
      }).addTo(self.map).setData([{
        center: self.center,
        radius: 400,
        styleId: "style1" //设置辐射圈样式配置id
      }])
      const keyframe = [
          { percentage: 0, rotation: 0 },
          { percentage: 0.5, rotation: 180 },
          { percentage: 1, rotation: 360 }
      ];
      self.map.startAnimation(keyframe, { duration: 1000*100, loop: Infinity });
    },
    Compare(property) {//property:根据什么属性排序
      return function(a,b){
        var value1 = a[property];
        var value2 = b[property];
        /*
        * value2 - value1; ——> 降序
        * value1 - value2; ——> 升序
        */
        return value2 - value1;//升序排序
      }
    },
    PopulationComposition(){
      this.$axios.get(`/api/population-composition/get`)
      .then(res => {
        let data = res.records;
        let rowData = [];
        let agesArr = Array.from(new Set(data.map(e=>e.ages)));
        let nameArr = Array.from(new Set(data.map(e=>e.name)));
        agesArr.forEach(e=>{
          let item = {
            name:e,
            props:[]
          };
          nameArr.forEach(r=>{
            let aim = data.find(t=>t.name==r&&t.ages==e&&t.personNumber)
            item.props.push({label:r,value:aim?aim.personNumber:0})
          })
          rowData.push(item)
        })
        this.populationComposition = {
          data:rowData,
          type:'bar',
          title:'人口年龄构成情况统计信息'
        }
      })
    },
    Households(){
      this.$axios.get(`/api/households/get`)
      .then(res => {
        let data = res.records;
        let yearArr = Array.from(new Set(data.map(e=>e.year)));
        let householdNumberArr = {name:'户数统计信息',props:[]}
        let personNumberArr = {name:'人口数统计信息',props:[]}
        let averageHouseholdNumberArr = {name:'平均每户数统计信息',props:[]}
        yearArr.forEach(e=>{
          let householdNumber = data.find(r=>r.year==e)?.householdNumber || 0;
          householdNumberArr.props.push({label:e,value:householdNumber})
          let personNumber = data.find(r=>r.year==e)?.personNumber || 0;
          personNumberArr.props.push({label:e,value:personNumber})
          let averageHouseholdNumber = data.find(r=>r.year==e)?.averageHouseholdNumber || 0;
          averageHouseholdNumberArr.props.push({label:e,value:averageHouseholdNumber})
        })
        let rowData = {
          householdNumber: [householdNumberArr],
          personNumber: [personNumberArr],
          averageHouseholdNumber: [averageHouseholdNumberArr]
        }
        this.households = {
          data:rowData,
          type:'line',
          title:'人口数、户数（常住）统计信息'
        };
      })
    },
    InfomationMobilization(){
      let self = this;
      const number = 50;
      let skillList = [
      {id: 60, attr: '计算机操作员'},
      {id: 71, attr: '电子计算机维修工'},
      {id: 99, attr: '计算机网络管理员'},
      {id: 200, attr: '软件设计工程师'}
      ];
      self.$axios.get(`/api/person-job/list/skillClear`).then(()=>{
        self.$axios.all([ 
          self.$axios.get(`/api/person-job/list/skillList?ids=${skillList.map(e=>e.id).toString()}`),
          self.$axios.get(`/api/person-job/list/skill?id=60&include=false&number=${number}`),
          self.$axios.get(`/api/person-job/list/skill?id=71&include=false&number=${number}`),
          self.$axios.get(`/api/person-job/list/skill?id=99&include=false&number=${number}`),
          self.$axios.get(`/api/person-job/list/skill?id=200&include=false&number=${number}`),
        ]).then(self.$axios.spread(function (SUM,计算机操作员, 电子计算机维修工,计算机网络管理员,软件设计工程师) {
          self.BathData(SUM,计算机操作员,'计算机操作员');
          self.BathData(SUM,电子计算机维修工,'电子计算机维修工');
          self.BathData(SUM,计算机网络管理员,'计算机网络管理员');
          self.BathData(SUM,软件设计工程师,'软件设计工程师');
          let prop = SUM.map(e=>{return {
             label: e.name, value: e.number
          }})
          let infomationMobilizationSum = [{
            name: "信息动员技能统计",
            props:prop
          }];
          self.infomationMobilizationSum = infomationMobilizationSum;
        }))
      })
    },
    BathData(SUM,res,name){
      let column = [
        {key:'name',name:'姓名'},
        // {key:'sex',name:'性别'},
        // {key:'IDCard',name:'身份证号'},
        // {key:'phone',name:'联系方式'},
        {key:'skillName',name:'技能名称'},
        {key:'skillLevel',name:'技能等级'},
      ]
      let row = res.map(e=>{return{
        name:e['人信息'].name,
        // IDCard:e['人信息'].IDCard,
        // sex:e['人信息'].sex,
        // phone:e['人信息'].phone,
        skillName:e['技能信息'].skillName,
        skillLevel:e['技能信息'].skillLevel,
      }})
      let data = {
        column:column,
        row:row,
        number:SUM.find(e=>e.name==name).number
      }
      this[name] = data;
    },
    PersonJobSkillList(){
      this.$axios.get(`/api/skill/get?pageSize=999`).then(res=>{
        let ids = '';
        res.records.forEach((e,i)=>{
          let str = ''
          str = (i==(res.records.length-1)?`${e.id}`:`${e.id},`)
          ids+=str
        });
        this.$axios.get(`/api/person-job/list/skillList?ids=${ids}`)
        .then(res2 => {
          let rowData = [].concat(res2);
          rowData.sort(this.Compare('number'));
          let DataBar = {name:'人数',props:[]}
          DataBar.props = rowData.map(e=>{
            return { label: e.name, value: e.number }
          })
          DataBar.props = DataBar.props.slice(0,20)
          let DataTree = rowData.map(e=>{
            return { name: e.name, value: e.number }
          })
          this.personJobSkill = {
            DataBar:[DataBar],
            DataTree:DataTree,
            type:'treemap',
            title:'技能透视'
          };
        })
      })
    },
    Hospital(){
      this.$axios.get(`/api/hospital/list`)
      .then(res => {
        let serviceMethod = [];
        res.records.forEach(e=>{
          let arr = e.serviceMethod.split(' ');
          arr.forEach(r=>{
            if(!serviceMethod.includes(r)){
              serviceMethod.push(r)
            }
          })
        })
        let rowData = {
          name:'战备医疗服务能力',
          props:[]
        }
        rowData.props = serviceMethod.map(e=>{
          return {label:e,value:0}
        })
        res.records.forEach(e=>{
          let arr = e.serviceMethod.split(' ');
          arr.forEach(r=>{
            rowData.props.find(t=>t.label==r).value+=1
          })
        })
        let emptyIndex = rowData.props.findIndex(e=>e.label=='');
        emptyIndex!==-1?rowData.props.splice(emptyIndex,1):null;
        this.hospital = {
          title: '战备医疗服务能力',
          type: 'bar',
          data: [rowData]
        };
      })
    },
    CountType10(){
      this.$axios.get(`/api/count/list?ids=10`)
      .then(res => {
        let rowData = {
          name: '高新科技企业分布',
          props: res.records.map(e=>{return{label:e.type,value:e.number}})
        }
        this.countType10 = {
          title: '高新科技企业分布',
          type: 'radar',
          data: [rowData]
        }
      })
    },
    CountType11(){
      this.$axios.get(`/api/count/list?ids=11`)
      .then(res => {
        let rowData = {
          name: '医疗机构分布',
          props: res.records.map(e=>{return{label:e.type,value:e.number}})
        }
        this.countType11 = {
          title: '医疗机构分布',
          type: 'bar',
          data: [rowData]
        }
      })
    },
    CountType5(){
      this.$axios.get(`/api/count/list?ids=5`)
      .then(res => {
        let rowData = {
          name: '常住人口',
          props: res.records.map(e=>{return{label:e.type,value:e.number}})
        }
        this.countType5 = {
          title: '常住人口',
          type: 'radar',
          data: [rowData]
        }
      })
    },
    CountType8(){
      this.$axios.get(`/api/count/list?ids=8`)
      .then(res => {
        let rowData = {
          name: '战备载具统计',
          props: res.records.map(e=>{return{label:e.type,value:e.number}})
        }
        this.countType8 = {
          title: '战备载具统计',
          type: 'pie',
          data: [rowData]
        }
      })
    },
    CountType6(){
      this.$axios.get(`/api/count/list?ids=6`)
      .then(res => {
        let rowData = {
          name: '可调度特种设备',
          props: res.records.map(e=>{return{label:e.type,value:e.number}})
        }
        this.countType6 = {
          title: '可调度特种设备',
          type: 'pie',
          data: [rowData]
        }
      })
    },
    SpecialEquipment(){
      this.$axios.get(`/api/special-equipment/get`)
      .then(res => {
        let column = [
          {key:'type',name:'设备种类'},
          {key:'unitName',name:'持证者名称'},
          // {key:'eCategory',name:'设备类别'},
          // {key:'cName',name:'使用单位名称'},
          // {key:'ePlace',name:'设备使用地点'},
          // {key:'eType',name:'设备品类'},
        ]
        let row = res.records.map(e=>{
          return {
            type:e.type,
            unitName:e.unitName,
            eCategory:e.eCategory,
            cName:e.cName,
            ePlace:e.ePlace,
            eType:e.eType,
          }
        })
        let data = {
          column:column,
          row:row,
        }
        this.specialEquipment = data;
      })
    },
    EmergencyEquipment(){
      this.$axios.get(`/api/emergency-equipment/list`)
      .then(res => {
        let column = [
          {key:'name',name:'名称'},
          {key:'oTypeName',name:'类别'},
          {key:'oTechName',name:'技术状况'},
          {key:'inroomTime',name:'入库时间'},
          {key:'num',name:'数量'},
        ]
        let row = res.records.map(e=>{
          return {
            name:e.name,
            oTypeName:e.oTypeName,
            oTechName:e.oTechName,
            inroomTime:e.inroomTime,
            num:e.num,
          }
        })
        let data = {
          column:column,
          row:row,
        }
        this.emergencyEquipment = data;
      })
    },
    PreEquipment(){
      this.$axios.get(`/api/pre-equipment/list`)
      .then(res => {
        let column = [
          {key:'name',name:'装备名称'},
          {key:'addr',name:'存放位置'},
          {key:'oTechName',name:'技术状况'},
          {key:'belongTo',name:'权属单位或个人'},
          {key:'num',name:'数量'},
        ]
        let row = res.records.map(e=>{
          return {
            name:e.name,
            addr:e.addr,
            oTechName:e.oTechName,
            belongTo:e.belongTo,
            num:e.num,
          }
        })
        let data = {
          column:column,
          row:row,
        }
        this.preEquipment = data;
      })
    },
    SchoolList(){
      this.$axios.get(`/api/school/list`)
      .then(res => {
        let column = [
          {key:'name',name:'名称'},
          {key:'schoolType',name:'类型'},
          {key:'addr',name:'地址'},
        ]
        let row = res.records.map(e=>{
          return {
            name:e.name,
            oTypeName:e.oTypeName,
            schoolType:e.schoolType,
            addr:e.addr,
          }
        })
        let data = {
          column:column,
          row:row,
        }
        this.schoolList = data;
      })
    },
    PortPortBerth(){
      this.$axios.get(`/api/port-port-berth/get`)
      .then(res => {
        let column = [
          {key:'name',name:'企业名称'},
          // {key:'workArea',name:'所在作业区'},
          {key:'berthName',name:'泊位名称'},
          // {key:'berthType',name:'泊位形式'},
          {key:'checkBerth',name:'核查靠泊能力'},
          {key:'berthLength',name:'泊位长度'},
          {key:'factHeight',name:'前沿实际水深'},
          {key:'pbPort',name:'所在港口'},
        ]
        let row = res.records.map(e=>{
          return {
            name:e.name,
            // workArea:e.workArea,
            berthName:e.berthName,
            // berthType:e.berthType,
            checkBerth:e.checkBerth,
            berthLength:e.berthLength,
            factHeight:e.factHeight,
            pbPort:e.pbPort,
          }
        })
        let data = {
          column:column,
          row:row,
        }
        this.portPortBerth = data;
      })
    },
    ComWareroom(){
      this.$axios.get(`/api/com-wareroom/get`)
      .then(res => {
        let column = [
          {key:'name',name:'企业名称'},
          // {key:'registerId',name:'入库登记编号'},
          {key:'code',name:'统一社会信用代码'},
        ]
        let row = res.records.map(e=>{
          return {
            name:e.name,
            registerId:e.registerId,
            code:e.code,
          }
        })
        let data = {
          column:column,
          row:row,
        }
        this.comWareroom = data;
      })
    },
    HighTechCom(){
      this.$axios.get(`/api/high-tech-com/get`)
      .then(res => {
        let column = [
          {key:'name',name:'企业名称'},
          {key:'techFiled',name:'技术领域'},
          {key:'addr',name:'镇区'},
        ]
        let row = res.records.map(e=>{
          return {
            name:e.name,
            techFiled:e.techFiled,
            addr:e.addr,
          }
        })
        let data = {
          column:column,
          row:row,
        }
        this.highTechCom = data;
      })
    },
    ResidentPopulation(){
      this.$axios.get(`/api/resident-population/get`)
      .then(res => {
        let residentPopulation = 0;
        res.records.forEach(e=>{
          residentPopulation+=e.personNumber
        })
        this.residentPopulation = residentPopulation;
      })
    },
    ClickTopItem(item){
      let currentTopItemStr = JSON.parse(JSON.stringify(this.currentTopItem));
      this.currentTopItem = null;
      if(currentTopItemStr!=item){
        setTimeout(()=>{this.currentTopItem = item},100)
      }
    }
  },
}
</script>
<style lang="scss">
@import '@/assets/main.scss';
</style>
