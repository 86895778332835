<template>
  <div class="loginArea">
    <div class="loginSysName">沛县智慧人武综合态势监测指挥平台</div>
    <div class="switchMode" v-if="IsLogin && showNav">
      <el-card class="loginCard">
        <div slot="header"><span style="cursor:pointer"><i class="el-icon-back" @click="showNav=false,IsLogin=false"></i> 系统模块</span></div>
        <div>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-button plain size="mini" class="_btn" icon="el-icon-s-data" @click="Go('dataSet')">
                数据可视化
                <br>
                <br>
                3840*2160
              </el-button>
            </el-col>
            <el-col :span="12">
              <el-button plain size="mini" class="_btn" icon="el-icon-s-flag" @click="Go('map')">
                电子沙盘
                <br>
                <br>
                2048*1200
              </el-button>
            </el-col>
          </el-row>
        </div>
      </el-card>
    </div>
    <div v-if="!IsLogin">
      <el-card class="loginCard">
        <div slot="header">登录</div>
        <div>
          <el-form ref="form" :model="form" label-width="80px">
            <el-form-item label="账号">
              <el-input v-model="form.account"></el-input>
            </el-form-item>
            <el-form-item label="密码">
              <el-input v-model="form.password" type="password"></el-input>
            </el-form-item>
            <el-form-item label=" ">
              <el-button type="primary" @click="Login" style="width:120px">登录</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-card>
    </div>
    <vue-particles
      v-if="(IsLogin && showNav) || !IsLogin"
      color="#42e0ff"
      :particleOpacity="0.4"
      :particlesNumber="10"
      shapeType="circle"
      :particleSize="20"
      linesColor="#dedede"
      :linesWidth="2"
      :lineLinked="false"
      :lineOpacity="1"
      :linesDistance="150"
      :moveSpeed="3"
      :hoverEffect="true"
      hoverMode="grab"
      :clickEffect="true"
      clickMode="push"
    >
    </vue-particles>
    <div class="loginBg" :style="{'background-image':loginBg}"></div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      showNav:true,
      IsLogin:false,
      loginBg:`url(${require('@/assets/login.jpg')})`,
      form:{
        account:null,
        password:null,
      }
    }
  },
  methods:{
    Go(page){
      this.$router.push({path:`${page}`});
      this.showNav = false;
    },
    Login(){
      this.$axios.post(
        `/api/admin/login`,
        this.$qs.stringify({account:this.form.account,password:this.form.password}),
        {headers:{'Content-Type': 'application/x-www-form-urlencoded'}}
        )
      .then(res => {
        localStorage.setItem('token',res.token);
        this.IsLogin = true;
        this.showNav = true;
      })
    }
  },
  mounted(){
    localStorage.removeItem('token')
    let token = localStorage.getItem('token');
    this.IsLogin = token?true:false;
    this.showNav = window.location.pathname!=='/'?false:true;
  },
  created(){
    let self = this;
    let key = null;
    document.onkeydown = function(e){
      if(window.event == undefined){
        key = e.keyCode;
      }else{
        key = window.event.keyCode;
      }
    //enter的ASCII码是13
      if(key == 13){
        self.Login();//t.登录方法
      }
    }
  },


}
</script>
