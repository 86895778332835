<template>
  <div class="cardContain">
    <div class="title" v-if="title">{{title}}</div>
    <div class="body corner">
      <el-carousel :height="height" direction="vertical" :autoplay="true" :interval="5000">
        <el-carousel-item v-for="(item,index) in resArr" :key="'carousel_'+index">
          <div class="reportDetail" v-for="(e,i) in item" :key="'carousel_'+index+'_'+i">
            <div class="type">{{e.type}} - {{e.status}}</div>
            <div>上报人 {{e.mName}}</div>
            <div>上报时间 {{e.sendTime}}</div>
            <div>{{e.des}}</div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      resArr:[]
    };
  },
  props:{
    title:{
        type:String,
        default(){return''}
    },
    height:{
      type:String,
      default(){return''}
    },
    rowData:{
        type:Array,
        default(){return []}
    },
  },
  watch:{
    rowData:{
      handler(val){
        this.resArr = this.splitData(val,3)
      },
      immediate:true,
      deep:true
    }
  },
  // mounted() {
  //   this.resArr = this.splitData(this.rowData,3);
  //   setInterval(()=>{
  //     this.resArr = this.splitData(this.rowData,3)
  //   },1000*60*2)
  // },
  methods: {
    splitData(arr,size){
        var num = [];
        for(var i=0;i<Math.ceil(arr.length/size);i++){
          var start = i*size;
          var end = start+size;
          num.push(arr.slice(start,end));
        }
        return num;
    }
  },
};
</script>