<template>
  <div class="cardContain">
    <div class="title">{{localTitle}}</div>
    <div class="body corner">
      <div :id="rowTitle+'_'+index" :style="{width: '100%', height: height}"></div>
    </div>
  </div>
</template>

<script>
  import * as echarts from 'echarts';
  export default {
    data() {
      return {
        index:Math.floor(Math.random()*1000)+Math.floor(Math.random()*1000),
        backgroundColor: 'transparent',
        myChart: null,
        localData: [],
        localType: '',
        localTitle: '',
        currentChart: null
      }
    },
    props:{
      height:{
          type:String,
          default(){return ''}
      },
      rowTitle:{
          type:String,
          default(){return ''}
      },
      rowType:{
          type:String,
          default(){return ''}
      },
      rowData:{
          type:Array,
          default(){return []}
      },
      changeTitle:{
          type:String,
          default(){return ''}
      },
      changeType:{
          type:String,
          default(){return ''}
      },
      changeData:{
          type:Array,
          default(){return []}
      },
      direction:{
          type:String,
          default(){return 'x'}
      },
      legend:{
          type:Boolean,
          default(){return true}
      }
    },
    watch:{
      rowData:{
        handler(val){
          this.localData = val;
          if(this.changeData.length>0 && this.changeType!=''){
            setInterval(()=>{this.ChangeData()},10*1000)
          }
        },
        immediate:true
      },
      rowType:{
        handler(val){
          this.localType = val
        },
        immediate:true
      },
      rowTitle:{
        handler(val){
          this.localTitle = val
        },
        immediate:true
      },
    },
    mounted() {
      this.Init();
    },
    methods: {
      Init(currentChart){
        if(this.myChart==null){
          this.myChart = echarts.init(document.getElementById(`${this.rowTitle}_${this.index}`),'dark');
        }
        let option = {
          animationDurationUpdate: 1000,
          color:this.$echartsColor,
          backgroundColor: this.backgroundColor,
          textStyle: { fontSize: 18 }
        };
        if(this.legend){
          let legend = this.localData.map(e=>e.name);
          option.legend = { data: legend, textStyle: { fontSize: 18 } }
        }
        let keys = null;
        if(this.localType!='treemap'){
          keys = this.localData[0].props.map(e=>e.label);
        }
        switch (this.localType) {
          case 'radar':
            option = {...option,...this.RadarOption(keys)}
            break;
          case 'bar':
            option = {...option,...this.BarOption(keys)}
            break;
          case 'pie':
            option = {...option,...this.PieOption(keys)}
            break;
          case 'line':
            option = {...option,...this.LineOption(keys)}
            break;
          case 'treemap':
            option = {...option,...this.TreemapOption()}
            break;
        }
        this.myChart.setOption(option,true);
        window.addEventListener("resize", this.ChartResize);
        this.currentChart = currentChart?currentChart:'rowData'
      },
      ChartResize(){
        this.myChart.resize({
            width: null,
            height: null,
            silent: false,
        })
      },
      ChangeData(){
        if(this.currentChart=='rowData'){
          this.localData = this.changeData;
          this.localType= this.changeType;
          this.localTitle= this.changeTitle;
          this.Init('changeData')
        }else{
          this.localData = this.rowData;
          this.localType= this.rowType;
          this.localTitle= this.rowTitle;
          this.Init('rowData')
        }
        
      },
      RadarOption(keys){
        let max = 0;
        let series = [{ 
          type: this.localType,
          symbol: "circle",
          symbolSize: 6,
          itemStyle: {
            normal: {
              color: this.$echartsColor[0],
              borderColor: '#fff',
              borderWidth: 8,
            },
          },
          areaStyle: {
            normal: {
              color: this.$echartsColor[0],
            },
          },
          lineStyle: {
            normal: {
              color: this.$echartsColor[0],
              width: 10,
            },
          },
          data: [] 
        } ];
        this.localData.forEach(e=>{
          let item = {name:e.name,value:[]};
          keys.forEach(r=>{ 
            let value = e.props.find(t=>t.label==r).value
            item.value.push(value);
            if(value>max){max = value}
          });
          series[0].data.push(item)
        })
        let indicator = keys.map(e=>{return {name:e,max:max}});
        return {
          radar: { indicator: indicator ,radius: ["0%", "50%"]},
          series: series
        };
      },
      BarOption(keys){
        let series = [];
        this.localData.forEach((e,i)=>{
          let item = {
            name:e.name,
            type: this.localType,
            stack: "total",
            label: { show: true ,fontSize:18 },
            silent: true,
            itemStyle: {
              color: this.$echartsColor[i],
              label: {
                normal: {
                  show: false,
                },
              }
            },
            data:[]
          };
          keys.forEach(r=>{ item.data.push(e.props.find(t=>t.label==r).value)});
          series.push(item)
        })
        return {
          grid: { left: "3%", right: "4%", bottom: "3%", containLabel: true },
          xAxis: { 
            type: "value" , 
            axisLabel: {
              textStyle: {
                color: '#fff',
                fontSize: 18,
              }
            } 
          },
          yAxis: { 
            type: "category", 
            axisLabel: {
              textStyle: {
                color: '#fff',
                fontSize: 18,
              }
            },
            data:keys,
          },
          series: series
        };
      },
      PieOption(keys){
        let series = [];
        // let title = []
        this.localData.forEach((e,i)=>{
          let width = (this.direction=='x'?Math.floor(100/this.localData.length):'100');
          let height = (this.direction=='y'?Math.floor(100/this.localData.length):'100');
          let left = (this.direction=='x'?Math.floor(100/this.localData.length):0)*i;
          let top = (this.direction=='y'?Math.floor(100/this.localData.length):0)*i;
          let item = {
            name:e.name,
            height:height+'%',
            width: width+'%',
            left:left+'%',
            top:top+'%',
            type: this.localType,
            radius:['40%','60%'],
            z:2,
            label: {
              normal: {
                formatter: function (params) {
                  return (
                    "{white|" + params.name + " " + params.value + "}"
                  );
                },
                rich: {
                  white:{
                    fontSize: 18,
                    color: "#fff",
                  }
                },
              },
            },
            itemStyle: {
              borderRadius: 30,
              borderColor: this.$echartsColor[i],
            },
            silent: true,
            roundCap: true,
            data:[]
          };
          keys.forEach(r=>{ 
            let aim = e.props.find(t=>t.label==r);
            item.data.push({value:aim.value,name:aim.label})
          });
          series.push(item)
        })
        return {
          series: series,
          // title: title,
        };
      },
      LineOption(keys){
        let series = [];
        this.localData.forEach((e,i)=>{
          let item = {
            name:e.name,
            type: this.localType,
            smooth: true, //是否平滑
            showAllSymbol: true,
            symbol: "circle",
            symbolSize: 25,
            stack: 'Total',
            lineStyle: {
              width: 10,
              color: this.$echartsColor[i],
              shadowColor: "rgba(225, 225, 225, 1)",
              shadowBlur: 0,
              shadowOffsetY: 5,
              shadowOffsetX: 5,
            },
            showSymbol: true,
            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: this.$echartsColor[i]
                },
                {
                  offset: 1,
                  color: this.$echartsColor[i]+'2b'
                }
              ])
            },
            label: {
              show: true,
              position: "bottom",
              textStyle: {
                color: "#fff",
              },
            },
          
            data:[]
          }
          keys.forEach(r=>{ item.data.push(e.props.find(t=>t.label==r).value)});
          series.push(item)
        })
        return {
          xAxis: {
            type: 'category',
            boundaryGap: false,
            axisLabel: {
              textStyle: {
                color: '#fff',
                fontSize: 18,
                rotate: 45
              }
            },
            data: keys
          },
          yAxis: {
            type: 'value',
            axisLabel: {
              textStyle: {
                color: '#fff',
                fontSize: 18,
                rotate: 45
              }
            },
          },
          smooth: true,
          series: series
        };
      },
      TreemapOption(){
        let max = 0;
        this.localData.forEach(e=>{
          e.value>max?max=e.value:null;
        })
        return {
          visualMap: {
            show: false,
            max: max,
            inRange: {
              color: this.$echartsColor, 
            },
          },
          series: [
            {
              name: "",
              type: this.localType,
              width: "100%",
              height: "100%",
              roam: false, //是否开启拖拽漫游（移动和缩放）
              nodeClick: false, //点击节点后的行为,false无反应
              label: {
                show: true,
                fontSize: 18,
                formatter: function (params) {
                  var arr = [params.name, params.value + "人"];
                  return arr.join("\n");
                },
              },
              itemStyle: {
                normal: {
                  borderColor: "#fff",
                },
              },
              data: this.localData,
            },
          ]
        }
      }
    },
    destroyed(){
      window.removeEventListener("resize", this.ChartResize);
    }
  }
</script>