<template>
<div class="cardContain">
    <div class="title">{{article.title.slice(0,20)}}...</div>
    <div class="body corner" style="display:flex;padding:10px 0">
      <div class="eArticle">
        <el-row :gutter="10">
          <el-col :span="24">
            <div class="articleTitle">{{article.title}}</div>
            <el-divider></el-divider>
          </el-col>
          <el-col :span="24">
            <div class="articleTime">{{article.releaseTime}}</div>
          </el-col>
          <el-col :span="24">
            <el-divider></el-divider>
            <div class="articleContent" v-html="article.content"></div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
 
<script>
  export default {
    props:{
      article:{
        type:Object,
        default(){return {}}
      },
    },
    // 监听属性 类似于data概念
  }
</script>

