<template>
  <div class="cardContain">
    <div class="title">{{title}}</div>
    <div class="body corner">
      <div class="eNumberArea" :style="'height:'+height">
        <div class="eNumberNum" v-for="item in numArr" :key="'key_'+item+Math.floor(Math.random()*1000)+'_'+item+Math.floor(Math.random()*1000)">{{item}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
     numArr:[]
    }
  },
  props:{
    title:{
        type:String,
        default(){return''}
    },
    height:{
      type:String,
      default(){return''}
    },
    rowData:{
        type:Number,
        default(){return '88888'}
    },
  },
  methods:{
    init(){
      let num = JSON.parse(JSON.stringify(this.rowData))+'';
      let numArr = [];
      for(let i=0;i<num.length;i++){
        numArr.push(num[i])
      }
      this.numArr = numArr;
    },
  },
  mounted(){
    this.init()
  }
}
</script>