<template>
  <div id="header">
      <div class="sysTop">
          <div class="sysName">沛县智慧人武综合态势监测指挥平台</div>
          <div class="timeStr">
            <span>{{timeStr}}</span>
          </div>
      </div>
      <div v-if="weather" class="weatherInfo">
        <div class="item">
          <el-tag>温度 {{weather.temp}}</el-tag>
          <el-tag>风向 {{weather.windDir}}</el-tag>
        </div>
        <div class="item">
          <el-tag>风力 {{weather.windScale}}</el-tag>
          <el-tag>风速 {{weather.windSpeed}} km/h</el-tag>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      timeStr:null,
      weather:null,
      timer:{
        time:null,
        weather:null,
      }
    }
  },
  methods:{
    init(){
      this.timer.time = setInterval(this.refreshTime, 1000);
      this.getWeather();
      this.timer.weather = setInterval(this.getWeather, 1000*60*60);
    },
    getTime() {
      let weeks = new Array('星期天', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六');
      let date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      let week = date.getDay();
      let hour = date.getHours();
      let minutes = date.getMinutes();
      let seconds = date.getSeconds();
      return year + '年' + month + '月' + day + '日' + '  ' + weeks[week] + '  ' + hour + ":" + minutes + ":" + seconds;
    },
    refreshTime() {
      let updataTime = this.getTime();
      this.timeStr = updataTime;
    },
    getWeather(){
      this.$axios.get("https://devapi.qweather.com/v7/weather/now?location=101190804&key=f8b1b31482af49c192d7cecc568ba1c2")
      .then(({data}) => {
        this.weather = data.now
      })
    }
  },
  mounted(){
    this.init()
  }
}
</script>