import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from '@/utils/request'
import qs from 'qs'
import './assets/theme/index.css'

import VueParticles from 'vue-particles'
Vue.use(VueParticles)

Vue.prototype.$axios = axios;
Vue.prototype.$qs = qs;
Vue.prototype.$echartsColor = ['#2cacfb','#6262f6', '#6e34fc', '#d167c7','#ec9f00'];
Vue.prototype.$mapColor = ['#fff','#d167c7','#ec9f00'];
// Vue.prototype.$echartsColor = ['#80ffa5','#01bfec','#0d8acb', '#164c65','#4cffda','#be9a21','#f44635','#229e4e','#5f41b1'];
Vue.prototype.$Map = window.TMap;
Vue.config.productionTip = false
Vue.use(ElementUI);

import { VueJsonp } from 'vue-jsonp'
Vue.use(VueJsonp)

new Vue({
  router,
  store,
  axios,
  render: h => h(App)
}).$mount('#app')
